<template>
  <section id="pf-ingreso-stock">

    <b-row>
      <b-col>
        <BrunaForm ref="stockRef" :fields="this.stock.fields" :card="this.stock.card" @change="codigoChange">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewStock">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table ref="stockMmpp" :configUrl="stock_config" :dataUrl="stock_data"
          :editUrl="stock_edit"></ag-grid-table>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import EliminarStockActions from "./actions/EliminarStockActions.vue";


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BrunaForm,
    AgGridTable,
    ToastificationContent,
    EliminarStockActions: EliminarStockActions,
    NumberCellEditor,

  },
  created() {
    this.loadMmpp()
  },
  data() {
    return {
      stock_config: useApiServices.StockSkuConfig,
      stock_data: useApiServices.StockSkuData,
      stock_edit: useApiServices.StockSkuEdit,

      formatData: {

        selected: false,
        selectBrunaTable: null,
        selectQadTable: null,

      },

      stock: {
        card: {
          title: "Agregar Stock MMPP",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: '',
            body: ""
          }
        },
        fields: [
          {
            label: "Semana",
            type: "input",
            id: 'semana',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Planta",
            type: "input",
            id: 'planta',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Codigo",
            type: "select",
            id: 'codigo',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
            ],
            value: null,
            show: true
          },
          {
            label: "Descripción",
            type: "input",
            id: 'descripcion',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            disabled: true,
            value: null,
            show: true
          },
          {
            label: "Congelado Kg",
            type: "input",
            id: 'congelado',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Enfriado Kg",
            type: "input",
            id: 'enfriado',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Total Kg",
            type: "input",
            id: 'total',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
        ]
      },

    };
  },
  methods: {

    addNewStock(event) {
      let data = this.stock.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      let _data = this.stock.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      if(_data.codigo) {
        _data.codigo = data.codigo.codigo
      }
      Promise.all([
        this.$refs.stockRef.validate(),
      ]).then(([stockValid]) => {
        if (stockValid) {
          // console.log(_data);
          // return 
          useApiServices.postStockSkuCreate(_data)
            .then((response) => {
              console.log(response);

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

              this.$refs.stockMmpp.refreshRows()

            })
            .catch((error) => {
              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });


    },

    loadMmpp() {
      useApiServices.getMmpp(this).then((response) => {
        // console.log(response.data);
        let mmpp_options = [
          { value: null, text: "Selecciona una opcion" }
        ]

        response.data.forEach(item => {

          mmpp_options.push({
            value: {
              id: item.id,
              semana: item.meta_data.semana,
              planta: item.meta_data.planta,
              codigo: item.meta_data.codigo,
              descripcion: item.meta_data.descripcion,
              congelado: item.meta_data.congelado,
              enfriado: item.meta_data.enfriado,
              total: item.meta_data.total
            },
            text: item.meta_data['codigo']

          })

        });
        this.findFieldByName(this.stock.fields, 'codigo').options = mmpp_options;
      })
        .catch((error) => {
          console.log(error);

        });
    },

    findFieldByName(fields, name) {
      return fields.find(field => field.id == name)
    },

    codigoChange(field) {
      // console.log("Change", field);
      //si cambia el codigo actualiza la descripción
      if (field.id == "codigo") {
        this.findFieldByName(this.stock.fields, 'descripcion').value = field.value.descripcion
      }

      if(field.id == "congelado" || field.id == "enfriado"){
        let congelado =  this.findFieldByName(this.stock.fields, 'congelado').value || 0 ; 
        let enfriado = this.findFieldByName(this.stock.fields, 'enfriado').value || 0 ;

        this.findFieldByName(this.stock.fields, 'total').value = congelado + enfriado;

      }
    },

  },
  mounted() {

  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>